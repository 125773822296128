<template>
  <div class="van-main register-page page">
    <van-form ref="signature" class="login-form signature">
      <p class="van-block-title">
        请授权平台为您开通电子签章服务，以便您签署电子签章合同
      </p>
      <p class="van-signature-title">
        管理员手机号码：{{ signatureForm.phone }}
      </p>
      <van-field
        v-model="signatureForm.verificationCode"
        center
        clearable
        label=""
        :rules="rules.verificationCode"
        placeholder="请输入短信验证码"
      />
      <van-button type="info" block :disabled="isDisabled" native-type="button" @click="send()">
        {{ codeTip }}
      </van-button>
      <van-checkbox v-model="checked" shape="square">
        我已阅读并同意安心签《服务协议》及《隐私声明》
      </van-checkbox>
      <van-button block type="info" native-type="button" :disabled="!checked" @click="submit">
        开户并授权
      </van-button>
    </van-form>
    <p class="examine-title">
      {{ constants.examineTitle }}
    </p>
  </div>
</template>

<script>
import { Field, Form, Button, Toast, NoticeBar, Checkbox } from 'vant'
export default {
  components: {
    [Field.name]: Field,
    [Form.name]: Form,
    [Button.name]: Button,
    [NoticeBar.name]: NoticeBar,
    [Checkbox.name]: Checkbox
  },
  data () {
    return {
      signatureForm: {
        userId: this._.get(this.$store.state, 'employeeInfo.id', ''),
        phone: this._.get(this.$store.state, 'employeeInfo.mobile', ''),
        verificationCode: ''
      },
      loading: {
        signature: false
      },
      checked: false,
      time: 60,
      codeTip: '发送验证码',
      isDisabled: false,
      rules: {
        verificationCode: [
          { required: true, message: '请输入短信验证码' },
          { pattern: this.constants.rulesInteger4, message: this.constants.rulesToolkitInteger4 }
        ]
      }
    }
  },
  created () {
  },
  methods: {
    // 验证验证码
    submit () {
      this.$refs.signature.validate().then(() => {
        this.loading.signature = true
        this.api.signature.verification({ id: this.signatureForm.userId, code: this.signatureForm.verificationCode }).then(result => {
          Toast.success('验证成功')
          this.$router.push({ path: '/signature/agreement' })
        }).finally(() => {
          this.loading.signature = false
        })
      })
    },
    send () {
      this.api.base.sendVerificationSms({ phone: this.signatureForm.phone, type: 1 }).then(result => {
        Toast.success('发送成功')
        var timeStop = setInterval(() => {
          this.time = this.time - 1
          if (this.time > 0) {
            this.codeTip = '重新发送' + this.time + 's'
            this.isDisabled = true
          } else {
            this.time = 60
            this.codeTip = '发送验证码'
            clearInterval(timeStop)
            this.isDisabled = false
          }
        }, 1000)
      }).finally(() => {
      })
    }
  }
}
</script>

<style>

</style>
