var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main register-page page" },
    [
      _c(
        "van-form",
        { ref: "signature", staticClass: "login-form signature" },
        [
          _c("p", { staticClass: "van-block-title" }, [
            _vm._v(" 请授权平台为您开通电子签章服务，以便您签署电子签章合同 ")
          ]),
          _c("p", { staticClass: "van-signature-title" }, [
            _vm._v(" 管理员手机号码：" + _vm._s(_vm.signatureForm.phone) + " ")
          ]),
          _c("van-field", {
            attrs: {
              center: "",
              clearable: "",
              label: "",
              rules: _vm.rules.verificationCode,
              placeholder: "请输入短信验证码"
            },
            model: {
              value: _vm.signatureForm.verificationCode,
              callback: function($$v) {
                _vm.$set(_vm.signatureForm, "verificationCode", $$v)
              },
              expression: "signatureForm.verificationCode"
            }
          }),
          _c(
            "van-button",
            {
              attrs: {
                type: "info",
                block: "",
                disabled: _vm.isDisabled,
                "native-type": "button"
              },
              on: {
                click: function($event) {
                  return _vm.send()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.codeTip) + " ")]
          ),
          _c(
            "van-checkbox",
            {
              attrs: { shape: "square" },
              model: {
                value: _vm.checked,
                callback: function($$v) {
                  _vm.checked = $$v
                },
                expression: "checked"
              }
            },
            [_vm._v(" 我已阅读并同意安心签《服务协议》及《隐私声明》 ")]
          ),
          _c(
            "van-button",
            {
              attrs: {
                block: "",
                type: "info",
                "native-type": "button",
                disabled: !_vm.checked
              },
              on: { click: _vm.submit }
            },
            [_vm._v(" 开户并授权 ")]
          )
        ],
        1
      ),
      _c("p", { staticClass: "examine-title" }, [
        _vm._v(" " + _vm._s(_vm.constants.examineTitle) + " ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }